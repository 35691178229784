<template>
  <v-app>

    <v-app-bar
      id="header"
      class="header tab-section"
      data-direction="row"
      data-down="sub-header"
      :height="headerHeight"
      fixed flat dark
    >

      <router-link
        data-down="categories"
        to="/"
      >
        <img
          src="@/assets/NG_logo_generalitat.png"
          srcset="@/assets/NG_logo_generalitat.svg"
          width="170"
          height="27"
        />
      </router-link>

      <v-spacer/>

      <template v-if="!mobile">

        <div class="main-menu">
          <btn
            v-for="item in menu"
            :key="item.text"
            :to="item.to"
            class="mx-1"
            small text
          >
            {{ item.text }}
          </btn>
        </div>

        <template v-if="!mobile">
          <v-menu
            v-if="userID"
            open-delay="250"
            rounded="2"
            content-class="elevation-2"
            open-on-hover
            offset-y
            bottom
          >

            <template v-slot:activator="{ on, attrs }">
              <btn to="/admin" v-on="on" v-bind="attrs" outlined small>
                Admin
              </btn>
            </template>

            <v-list class="mt-2" dense>

              <v-list-item
                v-for="(item,index) in admin"
                :key="index"
                :to="item.to"
                @click="item.logout && logout()"
                link
              >
                <v-list-item-icon class="mr-2">
                  <v-icon v-text="item.icon"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"/>
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-menu>

          <btn v-else to="/login" outlined small>
            Accés
          </btn>

        </template>

      </template>

      <v-app-bar-nav-icon
        v-else
        data-down="filters"
        :ripple="false"
        @click="mobileMenu = !mobileMenu"
      />

    </v-app-bar>

    <v-fade-transition v-if="mobile">
      <div
        v-show="mobileMenu"
        class="mobile-nav"
        :style="{ top: headerHeight + 'px' }"
        @click="mobileMenu = false"
      >
        <v-expand-transition>
          <v-list
            tag="nav"
            v-show="mobileMenu"
            @click="prevent"
          >

            <v-list-item
              v-for="item in menu"
              :key="item.to"
              :to="item.to"
              exact-active-class="primary--text"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.text"/>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-3"/>

            <template v-if="userID">
              <v-list-item
                v-for="(item,index) in mobileAdmin"
                :key="index"
                :to="item.to"
                :exact="exactButton"
                active-class="primary--text"
                @click="item.logout && logout()"
                link
              >
                <v-list-item-icon class="mr-2">
                  <v-icon v-text="item.icon"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"/>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item v-else to="/login">
              <v-list-item-content>
                <v-list-item-title v-text="'Accés'"/>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-expand-transition>
      </div>
    </v-fade-transition>

    <router-view/>

    <alert
      v-model="alert.show"
      :color="alert.type"
      :message="alert.message"
    />

  </v-app>
</template>

<script>
import Btn from '@/components/Btn';
import Alert from '@/components/Alert';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: { Alert, Btn },
  data: () => ({
    mobileMenu: false,
    menu: [
      { text: 'Novetats',      to: '/'             },
      { text: 'Disposicions',  to: '/disposicions' },
      { text: 'Quant a',       to: '/quant-a'      },
      { text: 'Enllaços',      to: '/links'        },
      { text: 'Ajuda',         to: '/ajuda'        }
    ],
    admin: [
      { text: 'Administrar continguts',           icon: 'mdi-folder',  to: '/admin' },
      { text: 'Perfil',           icon: 'mdi-account',  to: '/admin/profile' },
      { text: 'Tancar la sessió', icon: 'mdi-power',    logout: 1 },
    ]
  }),
  methods: {
    ...mapActions( 'auth', [ 'logout'] ),
    prevent(e) {
      e.stopPropagation();
    }
  },
  computed: {
    ...mapState( 'app', [ 'headerHeight', 'alert' ]),
    ...mapState( 'auth', [ 'userID' ]),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    exactButton() {
      return this.$route.name === 'Profile';
    },
    mobileAdmin() {
      return [
        { text: 'Admin', icon: 'mdi-table-large', to: '/admin' }
      ].concat( this.admin );
    }
  }
};
</script>

<style>
.header > div > * {
  position: relative;
  z-index: 2;
}
.progress {
  position: fixed;
  z-index: 7;
}
.main-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  z-index: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mobile-nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  transition: background-color 1s ease;
  z-index: 6;
}
</style>
